<template>
  <page-content>

    <common-table
        ref="table"
        search-placeholder="搜索设备MAC"
        path="web/iot/device/authorization/page"
        :queryParams="queryParams"
        :columns="columns">

      <template slot="search">
        <a-button @click="$refs.table.export('授权附赠记录')">导出Excel</a-button>
      </template>

    </common-table>


  </page-content>
</template>
<script>
export default {

  data() {
    return {
      getListInterval: null,
      queryParams: {
        queryImportRecord: true,
        type:1
      },
      vendorList:[]
    }
  },

  created() {
    this.getListInterval = setInterval(() => {
      this.getList()
    }, 30000)
    this.getVendorList()
  },
  destroyed() {
    clearInterval(this.getListInterval)
  },

  computed: {
    columns() {
      let vendorFilter = [{text: '全部', value: ''}]
      this.vendorList.forEach(item=>{
        vendorFilter.push({text: item.name, value: item.id})
      })
      return [
        {
          title: '设备MAC',
          dataIndex: 'name',
        },
        {
          title: '导入额度',
          dataIndex: 'limitChange',
        },
        {
          title: '导入时间',
          dataIndex: 'createdAt',
          sorter: true
        },
      ]
    }
  },
  methods: {
    getList() {
      this.$refs.table.getData()
    },
    getVendorList(){
      this.$get('vendor?pageSize=100').then((r) => {
        let data = r.data
        this.vendorList = data.rows
      })
    }

  }
}
</script>
<style lang="less" scoped>

</style>
